<template>
  <div>
    <div class="d-flex mb-4 align-center justify-content-between">
      <h4 class="page-title mb-0 ">
        Элементы баннера
      </h4>
      <button class='button_add' @click.prevent='$router.push({name: "BannerItemCreate", params: {bannerId: `${bannerId}`}})'>
        Добавить новый элемент баннера
      </button>
    </div>
    <div class='widget'>
      <table class='table table-hover' v-if='bannerItems'>
        <thead class='table_head'>
        <tr>
          <th>Номер в списке</th>
          <th>Картинка</th>
          <th>Картинка на телефоне</th>
          <th>Ссылка</th>
          <th class='text-center actions'>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='bannerItem in bannerItems' :key='bannerItem.id'>
          <td>{{ getOrder(bannerItem.order) }}</td>
          <td><img class="banner-item-image" :src="bannerItem.background_image" alt="background_image"></td>
          <td><img class="banner-item-image" v-if="bannerItem.background_image_mobile" :src="bannerItem.background_image_mobile" alt="background_image"></td>
          <td>{{ bannerItem.link }}</td>
          <td class="d-flex justify-content-center">
            <router-link
              class='btn mr-2 btn-primary'
              :to="{name: 'BannerItemEdit', params: {bannerId: bannerId, bannerItemId: bannerItem.id}}"
            >
              <span class='glyphicon glyphicon-edit'></span>
            </router-link>
            <a
              class='btn btn-danger'
              @click.prevent='deleteRow(bannerItem.id, bannerItem.code)'
            >
              <span class='fa fa-trash-o delete'></span>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else> У этого банера нет элементов </div>
      <div class='overflow-auto'>
        <b-pagination
          class='paginator'
          v-if='pagination.total'
          v-model='pagination.current_page'
          :total-rows='pagination.total'
          :per-page='pagination.per_page'
          aria-controls='my-table'
        ></b-pagination>
      </div>

    </div>
  </div>

</template>

<script>
import PaginationMixin from "@/mixins/paginationMixin";
import DeleteModal from "@/components/Modal/DeleteModal.vue";

export default {
  name: "BannerItemsTable",
  mixins: [PaginationMixin],
  data() {
    return {
      bannerItems: null,
    }
  },
  computed: {
    bannerId() {
      return this.$route.params.id
    }
  },
  methods: {
    async updateRouter() {
      await this.$router.push({ query: this.routerParams });
      await this.fetchBannerItems();
    },
    async fetchBannerItems() {
      try {
        const { data } = await this.$axios.get('/crm/banners/'+ this.bannerId +'/banner-items/', {
          params: {
            ...this.$route.query,
          },
        });
        if (data && data.data && data.data.length) {
          this.parsePaginationMeta(data);
          this.bannerItems = data.data;
        }
        return null;
      } catch (e) {
        alert(e);
      }
    },
    deleteRow(id, name, index) {
      this.$modal.show(
        DeleteModal,
        {
          recordId: id,
          recordName: name,
          deleteHandler: async (id) => {
            try {
              const { data } = await this.$axios.delete('/crm/banners/'+ this.bannerId +'/banner-items/' + id, { id });
              this.$toasted.success('Баннер удален!', { position: 'bottom-right' });
              await this.fetchBannerItems();
            } catch (e) {
              const { data } = e.response;
              this.$toasted.error(data.message, { position: 'bottom-right' });
            }
          },
        }, { name: 'DeleteModal' },
      );
    },
    getOrder(order) {
      return order ? order : 'Не установлен'
    }
  },
  async created() {
    await this.fetchBannerItems();
  },
}
</script>

<style lang="scss" scoped>
.banner-item-image {
  max-width: 100px;
}
</style>