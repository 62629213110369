<template>
    <div class='content_pages-page'>
        <h4 class='page-title'>Страницы сайта <i class='fa fa-edit' aria-hidden='true'></i></h4>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <TableSearchBar class='col-4' @onSearch='changeQueryParams($event, "search")' />
                <div class='col-4 text-right'>
                    <button class='button_add' @click.prevent='$router.push({name: "ContentCreate"})'>
                        Добавить страницу сайта
                    </button>
                </div>
            </div>
            <div class='input-group col-4'>

            </div>
        </div>
        <div class='widget'>
            <table class='table table-hover' v-if='contentPages'>
                <thead class='table_head'>
                <tr>
                    <th>Slug</th>
                    <th>Title</th>
                    <th>Заголовок страницы</th>
                    <th>Description</th>
                    <th>Keywords</th>
                    <th>Язык</th>
                    <th class='text-center actions'>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='pageContent in contentPages' :key='pageContent.id'>
                    <td>{{ pageContent.slug }}</td>
                    <td>{{ pageContent.page_title }}</td>
                    <td>{{ pageContent.content_title }}</td>
                    <td>{{ pageContent.content_description }}</td>
                    <td>{{ pageContent.content_keywords }}</td>
                    <td>{{ pageContent.language }}</td>
                    <td class="d-flex justify-content-center">
                        <router-link
                            class='btn mr-2 btn-primary'
                            :to="{name: 'ContentEdit', params: {id: pageContent.id}}"
                        >
                            <span class='glyphicon glyphicon-edit'></span>
                        </router-link>
                        <a
                            class='btn btn-danger'
                            @click.prevent='deleteRow(pageContent.id, pageContent.code)'
                        >
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class='overflow-auto'>
                <b-pagination
                    class='paginator'
                    v-if='pagination.total'
                    v-model='pagination.current_page'
                    :total-rows='pagination.total'
                    :per-page='pagination.per_page'
                    aria-controls='my-table'
                ></b-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import PaginationMixin from '../../mixins/paginationMixin';
import TableSearchBar from '../../components/SearchBar/TableSearchBar';
import DeleteModal from '../../components/Modal/DeleteModal';

export default {
    name: 'ContentPages',
    components: { TableSearchBar },
    mixins: [PaginationMixin],
    data() {
        return {
            contentPages: null,
        };
    },
    watch: {},
    methods: {
        async updateRouter() {
            await this.$router.push({ query: this.routerParams });
            await this.fetchContentPages();
        },
        async fetchContentPages() {
            try {
                const { data } = await this.$axios.get('api/pages', {
                    params: {
                        ...this.$route.query,
                    },
                });
                if (data && data.data && data.data.length) {
                    this.parsePaginationMeta(data);
                    this.contentPages = data.data;
                }
                return null;
            } catch (e) {
                alert(e);
            }
        },
        deleteRow(id, name, index) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: async (id) => {
                        try {
                            const { data } = await this.$axios.delete('/api/pages/' + id, { id });
                            this.$toasted.success('Запись удалена!', { position: 'bottom-right' });
                            await this.fetchContentPages();
                        } catch (e) {
                            const { data } = e.response;
                            this.$toasted.error(data.message, { position: 'bottom-right' });
                        }
                    },
                }, { name: 'DeleteModal' },
            );
        },
    },
    async created() {
        await this.fetchContentPages();
    },
};
</script>

<style lang="scss" scoped>

</style>
